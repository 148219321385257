import { TagColorType } from "types/TagColorType";

export function getTagTextColor(color: TagColorType) {
  switch (color) {
    case TagColorType.GRAY:
      return "text-gray-600";
    case TagColorType.GREEN:
      return "text-green-700";
    case TagColorType.YELLOW:
      return "text-yellow-800";
    case TagColorType.RED:
      return "text-red-700";
    default:
      return "text-gray-600";
  }
}
